import { useEffect, useState } from "react";
export function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    // Add event listeners
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
  }

  export  function getMousePos(cont, evt, lastPoint) {
    const rect = cont.current.getBoundingClientRect();
    let currentX =  evt.clientX - rect.x;
    let currentY = evt.clientY - rect.y;
    
    if(evt.target.id===""){
      currentX = currentX - 21
      currentY = currentY - 21
      if (currentX < 0 || currentX > 600 || currentY < 0 || currentY > 800) {
        if(lastPoint.x == 600 || lastPoint.x == 0){
          return {x: lastPoint.x, y: currentY > 800 ? 800 : currentY < 0 ? 0: currentY };
        }
        if(lastPoint.y == 800 || lastPoint.y == 0){
          return {x: currentX > 600 ? 600 : currentX < 0 ? 0: currentX, y: lastPoint.y}
        }
      let intersectionPoints = []
      // console.log(currentX, currentY, lastPoint)
      // console.log(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800))
      // console.log()
      if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 600, 0)){
        intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 600, 0));
      }
      if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 800, 600, 800)){
        intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 800, 600, 800));
      }
      if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800)){
        intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800));
      }
      if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 0, 800)){
        intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 0, 800));
      }
    
      for(let i = 0; i < intersectionPoints.length; i++){
        let intersectionPointX = intersectionPoints[0].x
        let intersectionPointY = intersectionPoints[0].y
        let minX = Math.min(currentX, lastPoint.x)
        let minY = Math.min(currentY, lastPoint.y)
        let maxX = Math.max(currentX, lastPoint.x)
        let maxY = Math.max(currentY, lastPoint.y)
        if(intersectionPointX >= minX && intersectionPointX <= maxX && intersectionPointY >= minY && intersectionPointY <= maxY){
          return {
            x: intersectionPointX,
            y: intersectionPointY
          }
        }
    
      }
      }
    }
      return {
        x: evt.clientX - rect.x,
        y: evt.clientY - rect.y,
      };
  }
  
  export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
      return false
    }
  
    let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))
  
    // Lines are parallel
    if (denominator === 0) {
      return false
    }
  
    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator
  
    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
      return false
    }
  
    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)
  
    return {x: Math.round(x), y: Math.round(y)}
  }