import gql from "graphql-tag";

export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    densepose_correction(where: {id: {_eq: $id}}) {
        is_pred_good
        isLabelled
        id
        torso
        right_hand
        left_hand
        left_foot
        right_foot
        upper_right_leg
        upper_left_leg
        lower_left_leg
        lower_right_leg
        upper_left_arm
        upper_right_arm
        lower_left_arm
        lower_right_arm
        head
        background
        person_id
        map_person {
          image_url
        }
        reason_for_na
        is_na
        prediction_type
        map_grapy{
          id
        }
    }
  }
`;


export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $torso: String
    $right_hand: String
    $left_hand: String
    $left_foot: String
    $right_foot: String
    $upper_right_leg: String
    $upper_left_leg: String
    $lower_left_leg: String
    $lower_right_leg: String
    $upper_left_arm: String
    $upper_right_arm: String
    $lower_left_arm: String
    $head: String
    $lower_right_arm: String
    $background: String
    $prediction_type: String
    $name: String
    $seconds_spent: Int
    $last_updated: timestamptz
  ) {
    update_densepose_correction(
      where: { id: { _eq: $id } }
      _set: { 
          torso: $torso,
          right_hand: $right_hand,
          left_hand: $left_hand,
          left_foot: $left_foot,
          right_foot: $right_foot,
          upper_right_leg: $upper_right_leg,
          upper_left_leg: $upper_left_leg,
          lower_left_leg: $lower_left_leg,
          lower_right_leg: $lower_right_leg,
          upper_left_arm: $upper_left_arm,
          upper_right_arm: $upper_right_arm,
          lower_left_arm: $lower_left_arm,
          head: $head,
          lower_right_arm: $lower_right_arm,
          background: $background,
          isLabelled: "Labelled",
          is_na: "No",
          reason_for_na: "No",
          prediction_type: $prediction_type,
          name: $name,
          seconds_spent: $seconds_spent,
          last_updated: $last_updated,
        }
    ) {
      affected_rows
    }
  }
`;

export const allParts = [
  {
    variable : "head",
    color: "rgba(51,170,221,255)",
    // color: "#33aadd",
    name: "HEAD"
  },
  
  {
    variable: "background",
    // color: "rgba()",
    color: "#cccccc",
    name: "BACKGROUND"
  },
  {
    variable : "torso",
    color: "rgba(255,85,0, 255)",
    name: "TRUNK"
  },
  {
    variable: "upper_right_arm",
    color: "rgba(52,86,128, 255)",
    name: "UPPER LEFT ARM"
  },
  {
    variable: "upper_left_arm",
    color: "rgba(85,51,0, 255)",
    name: "UPPER RIGHT ARM"
  },
  {
    variable: "lower_right_arm",
    color: "rgba(0,0,255, 255)",
    // color: "#0000ff",
    name: "LOWER LEFT ARM"
  },
  {
    variable: "lower_left_arm",
    color: "rgba(0,128,0, 255)",
    name: "LOWER RIGHT ARM"
  },
{
    variable: "right_hand",
    color: "rgba(121, 35, 196,255)",
    // color: "#7923c4",
    name: "LEFT HAND"
},
{
    variable: "left_hand",
    color: "rgba(255,0,0, 255)",
    // color: "#c855c8",
    name: "RIGHT HAND"
},         
{
  variable: "upper_right_leg",
  color: "rgba(255, 255, 38,255)",
  // color: "#000055",
  name: "UPPER LEFT LEG"
},
{
  variable: "upper_left_leg",
  color: "rgba(170,0,118,255)",
  // color: "#0077dd",
  name: "UPPER RIGHT LEG"
},
{
  variable : "lower_right_leg",
  color: "rgba(85,85,0,255)",
  // color: "#555500",
  name: "LOWER LEFT LEG"
},
{
  variable: "lower_left_leg",
  color: "rgba(0,85,85, 255)",
  // color: "#005555",
  name: "LOWER RIGHT LEG"
},
{
  variable : "right_foot",
  color: "rgba(0, 255, 0,255)",
  // color: "#ffff00",
  name: "LEFT FOOT"
},
{
  variable: "left_foot",
  color: "rgba(0,0,85,255)",
  // color: "#aa0033",
  name: "RIGHT FOOT"
},
]


export const PRED_GOOD_AVAILABLE = false

export const TABLE = "densepose_correction"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/densepose_correction/`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/densepose_correction/`

export const NEW_PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/final_densepose_predictions/`

export const PREDICTION_EXT = `.png`

export const UPDATE_PRED_GOOD = gql`mutation MyMutation($id: Int, $is_pred_good: String, $isLabelled: String) {
  update_densepose_correction(where: {id: {_eq: $id}}, _set: {is_pred_good: $is_pred_good, isLabelled: $isLabelled}) {
    affected_rows
  }
}`;

export const RELATED = false

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  densepose_correction_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const GET_NA_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  densepose_correction_aggregate(distinct_on: id, where: {is_na: {_eq: "Yes"}, isLabelled: {_eq: "Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
      reason_for_na
    }
  }
}
`;

export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!,
    $last_updated: timestamptz
  ){
      update_densepose_correction(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na,
          last_updated: $last_updated
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;

export const PASSWORD_QUERY = gql`
query MyQuery($password: String!) {
  password_for_tools_aggregate(where: {tool_name: {_eq: "DENSEPOSE"}, password: {_eq: $password}}) {
    aggregate {
      count
    }
    nodes {
      locked_ids
    }
  }
}
`;

export const WORST_CASE_QUERY = gql`
query  MyQuery($id: Int!, $worst_case_date: String) {
  densepose_correction(order_by: {id: asc}, where: {worst_case_date: {_eq: $worst_case_date}, id: {_gte: $id}}, limit: 2) {
    id
  }
}
`;

export const WORST_CASE_PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/final_densepose_worst_case/`